import { render, staticRenderFns } from "./MBTI.vue?vue&type=template&id=345daf7e&scoped=true&"
import script from "./MBTI.vue?vue&type=script&lang=js&"
export * from "./MBTI.vue?vue&type=script&lang=js&"
import style0 from "./MBTI.vue?vue&type=style&index=0&id=345daf7e&scoped=true&lang=less&"
import style1 from "./MBTI.vue?vue&type=style&index=1&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "345daf7e",
  null
  
)

export default component.exports